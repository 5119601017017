import {useContext }                                  from "react";
import Context                                        from "../../Provider/Context";

import Button                                         from "@mui/material/Button";
import DialogActions                                  from "@mui/material/DialogActions";
import { _lang_pop_up_business, _lang_base }          from "../../Languages/languages";

function PopUpBusiness(props) {
  const { PopUps, SocialLinks }             = useContext(Context);

  const close_pop_up = () => {
    props.set_open_pop_up_business(false);
    PopUps.Update({ business_banner: { date: new Date(), show: false } });
  }

  const open_link = () => {
    props.set_open_pop_up_business(false);
    PopUps.Update({ business_banner: { date: new Date(), show: false } });
    window.open(SocialLinks.link_business, '_blank');
  }

  return (
    <>
      <div className="profile">
        <img onClick={open_link} src={`https://projectdow.com/data/assets/img/business/${_lang_base.lang_base}${props.background_img}`} alt={_lang_base.web_site_name} />
        <DialogActions>
          <Button onClick={open_link} >{_lang_pop_up_business.btn_access}</Button>
          <Button onClick={close_pop_up} >{_lang_pop_up_business.btn_exit}</Button>
        </DialogActions>
      </div>
    </>
  )
}

export default PopUpBusiness;
