const _lang_product_ui  = {
  product_properties: {
    game: "GAME",
    status: "STATUS",
    type: "TYPE",
    platform: "PLATFORM",
    compatible: "COMPATIBLE",
  },
  btn_video: "VIDEO",
  label_favorite: "Favorite",
  label_report_problem: "Report Problem",
  label_change_logs: "Change Logs",
  label_request_mod: "Request a Mod",
  text_btn_inject: "Check the Loader",
  products_reports: {
    "0": {
      msg: "Activated Successfully!",
      type: "success",
      typeError: "",
      variant: "success"
    },
    "1003": {
      msg: "Your HWID is different",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "1004": {
      msg: "Your access is paused",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "1005": {
      msg: "Your access has ended",
      type: "error",
      typeError: "compra",
      variant: "error"
    },
    "1006": {
      msg: "You do not have access to this product",
      type: "error",
      typeError: "compra",
      variant: "error"
    },
    "1007": {
      msg: "No access to any product",
      type: "error",
      typeError: "compra",
      variant: "error"
    },
    "1008": {
      msg: "You need to recharge",
      type: "error",
      typeError: "carregar",
      variant: "error"
    },
    "1010": {
      msg: "It is not over yet",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "1080": {
      msg: "Your HWID is blocked",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "3002": {
      msg: "Invalid PC time",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "4002": {
      msg: "mod access disabled",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "10": {
      msg: "Loader Closed",
      type: "error",
      typeError: "suporte",
      variant: "error"
    },
    "11": {
      msg: "Open Loader",
      type: "carregando",
      typeError: "suporte",
      variant: "carregando"
    },
  },
}

module.exports = { _lang_product_ui };
