import { useContext, useEffect }                    from "react";
import { useNavigate, useParams  }                  from "react-router-dom";
import { Swiper, SwiperSlide }                      from "swiper/react";
import { Autoplay }                                 from "swiper/modules";

import { useReferralCode }                          from "../../Hooks/ReferralCode";
import EffectAutoEscrita                            from "../../Components/Common/EffectAutoEscrita";
import Footer                                       from "../../Components/Footer/Footer";
import Loading                                      from "../../Components/Loading/Loading";

import Context                                      from "../../Provider/Context";

/* IMPORT IMAGENS */
import social_bg                                    from "../../assets/img/bg/social_bg.png";
import area_bg02                                    from "../../assets/img/bg/area_bg02.jpg";
import fun_fact_shape                               from "../../assets/img/others/fun_fact_shape.png";
import fun_fact                                     from "../../assets/img/others/fun_fact.png";
import trophy                                       from "../../assets/img/icons/trophy.png";
import circle                                       from "../../assets/img/icons/circle.svg";
import slider_bg                                    from "../../assets/img/slider/slider_bg.jpg";
import slider_img01                                 from "../../assets/img/slider/slider_img01.png";
import slider_shape01                               from "../../assets/img/slider/slider_shape01.png";
import slider_shape02                               from "../../assets/img/slider/slider_shape02.png";
import slider_shape03                               from "../../assets/img/slider/slider_shape03.png";
import slider_shape04                               from "../../assets/img/slider/slider_shape04.png";

/* IMPORT LANGUAGE */
import { _lang_home, _lang_base } from "../../Languages/languages";

const importAll                   = (context) => context.keys().map(context);
const imagesGalery                = importAll(require.context('../../assets/img/gallery', false, /\.(jpg)$/));
const imagesProducts              = importAll(require.context('../../assets/img/products', false, /\.(png)$/));

function Home() {
  const { slug }                                          = useParams();

  const history                                           = useNavigate();
  const { SocialLinks, SocialValues }                     = useContext(Context);
  const { referralCode }                                  = useReferralCode(slug);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { referralCode(slug); }, []);

  return(
    <>
      <Loading timer={1000} />
      <main className="main--area">
        <section className="slider__area slider__bg" style={{ backgroundImage: `url(${slider_bg})` }}>
          <div className="slider-activee">
            <div className="single-slider">
              <div className="container custom-container">
                <div className="row justify-content-between">
                  <div className="col-lg-6">

                    <div className="slider__content">
                      <h2 className="title ">{_lang_base.web_site_name}</h2>
                      <p>{_lang_home.slider__area.header_text}</p>
                      <div className="slider__btn">
                        <div onClick={()=> history('/ui')} className="tg-btn-1"><span>{_lang_home.slider__area.header_btn}</span></div>
                      </div>
                    </div>

                  </div>
                  <div className="col-xxl-6 col-xl-5 col-lg-6">
                    <div className="slider__img text-center">
                      <img src={slider_img01} alt={_lang_base.web_site_name} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      
          <div className="slider__shapes">
            <img src={slider_shape01} alt={_lang_base.web_site_name} />
            <img src={slider_shape02} alt={_lang_base.web_site_name} />
            <img src={slider_shape03} alt={_lang_base.web_site_name} />
            <img src={slider_shape04} alt={_lang_base.web_site_name} />
          </div>
      
          <div className="slider__brand-wrap">
            <div className="container custom-container">
              <ul className="slider__brand-list list-wrap"></ul>
            </div>
          </div>
        </section>
      
        <div className="area-background" style={{ backgroundImage: `url(${area_bg02})` }}>
          <section className="about__area-two section-pt-160 section-pb-190">
            <div className="container">
              <div className="row justify-content-center align-items-center">
                <div className="col-xl-6 col-lg-6 order-0 order-lg-2">
                  <div className="about__funFact-images">
                    <img src={fun_fact_shape} alt={_lang_base.web_site_name} className="bg-shape" />
                    <img src={fun_fact} className="main-img" alt={_lang_base.web_site_name} />
                  </div>
                  <div className="about__funFact-trophy">
                    <div className="icon"><img src={trophy} alt={_lang_base.web_site_name} /></div>
                    <div className="content">
                      <h5>{_lang_home.about__area.about__funFact}</h5>
                      <span>{_lang_base.web_site_name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-10">
                  <div className="section__title text-start mb-30">
                    <h3 className="title">{_lang_home.about__area.title}</h3>
                  </div>
                  <div className="about__content-two">
                    <p>{_lang_home.about__area.about__content}</p>
                  </div>
                  <div className="about__content-bottom">
                    <div className="about__content-circle">
                      <img src={circle} alt={_lang_base.web_site_name} />
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" version="1.1">
                        <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
                        <text>
                          <textPath href="#textPath">{_lang_home.about__area.textPath}</textPath>
                        </text>
                      </svg>
                    </div>
                    <div className="about__funFact-wrap">
                      <div className="about__funFact-lists">
                        <div className="about__funFact-item">
                          <h2 className="count">
                            <span className="odometer">{SocialValues.total_users.substring(0, 3)}</span>
                            <span className="formatting-mark">K</span>
                          </h2>
                          <p>{_lang_home.about__area.info_serve.members}</p>
                        </div>
                        <div className="about__funFact-item">
                          <h2 className="count">
                            <span className="odometer">{SocialValues.total_mods}</span>
                            <span className="formatting-mark"></span>
                          </h2>
                          <p>{_lang_home.about__area.info_serve.mods}</p>
                        </div>
                        <div className="about__funFact-item">
                          <h2 className="count">
                            <span className="odometer">{SocialValues.total_years}</span>
                            <span className="formatting-mark"></span>
                          </h2>
                          <p>{_lang_home.about__area.info_serve.years}</p>
                        </div>
                      </div>
                      <div className="about__content-btns">
                        <div onClick={()=> window.open(SocialLinks.link_youtube)} className="popup-video"><i className="fas fa-play"></i><span className="text">{_lang_home.about__area.about_btn.about_us}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className="streamers__area section-pt-95 section-pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-10">
                  <div className="section__title text-center mb-60">
                    <EffectAutoEscrita texto={_lang_home.streamers__area.animate_text} />
                    <h3 className="title">{_lang_home.streamers__area.header_text}</h3>
                  </div>
                </div>
              </div>
              <div className="swiper-container streamers-active">
                <Swiper 
                  slidesPerView={5}
                  spaceBetween={30}
                  autoplay={{ delay: 600 }}
                  modules={[ Autoplay]}
                  className="swiper-wrapper"
                >
                  {imagesProducts.map((item, index) =>
                  <SwiperSlide key={index} className="swiper-slide">
                    <div className="streamers__item">
                      <div className="streamers__thumb">
                        <img src={item} alt={_lang_base.web_site_name} />
                      </div>
                    </div>
                  </SwiperSlide>
                  )}
                </Swiper>
              </div>
            </div>
          </section>
        </div>
      
        <section className="project-area project-bg section-pt-120 section-pb-140">
          <div className="container custom-container">
            <div className="project__wrapper">
              <div className="section__title text-start">
                <h3 className="title">{_lang_home.project_area.header_text}</h3>
                <EffectAutoEscrita texto={_lang_home.project_area.animate_text} />
              </div>
              <div className="swiper-container project-active">
                <Swiper
                  slidesPerView={4}
                  centeredSlides={true}
                  spaceBetween={30}
                  autoplay={{ delay: 600 }}
                  modules={[Autoplay]}
                  className="swiper-wrapper"
                >
                {imagesGalery.map((item, index) =>
                 <SwiperSlide key={index} className="swiper-slide">
                    <div className="project__item">
                      <div className="popup-image"><img src={item} alt={_lang_base.web_site_name} /></div>
                    </div>
                 </SwiperSlide>
                )}
                </Swiper>
              </div>
              <div className="slider-button-prev">
                <i className="flaticon-right-arrow"></i>
                <i className="flaticon-right-arrow"></i>
              </div>
            </div>
          </div>
          <div className="swiper-scrollbar"></div>
        </section>
      
        <section className="social__area social-bg" style={{ backgroundImage: `url(${social_bg})` }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-md-10">
                <div className="section__title text-center mb-60">
                  <EffectAutoEscrita texto={_lang_home.social_area.animate_text} />
                  <h3 className="title">{_lang_home.social_area.header_text}</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center gutter-20 row-cols-2 row-cols-lg-6 row-cols-md-4 row-cols-sm-3">
              <div className="col">
                <div className="social__item">
                  <div onClick={()=>window.open(SocialLinks.link_facebook,'_blank')}>
                    <i className="flaticon-facebook"></i>
                    <span>facebook</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="5" viewBox="0 0 65 5">
                      <path d="M968,5630h65l-4,5H972Z" transform="translate(-968 -5630)" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="social__item">
                  <div onClick={()=>window.open(SocialLinks.link_twiter,'_blank')}>
                    <i className="flaticon-twitter"></i>
                    <span>twitter</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="5" viewBox="0 0 65 5">
                      <path d="M968,5630h65l-4,5H972Z" transform="translate(-968 -5630)" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="social__item">
                  <div onClick={()=>window.open(SocialLinks.link_instagram,'_blank')}>
                    <i className="flaticon-instagram"></i>
                    <span>instagram</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="5" viewBox="0 0 65 5">
                      <path d="M968,5630h65l-4,5H972Z" transform="translate(-968 -5630)" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="social__item">
                  <div onClick={()=>window.open(SocialLinks.link_youtube,'_blank')}>
                    <i className="flaticon-youtube"></i>
                    <span>youtube</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="5" viewBox="0 0 65 5">
                      <path d="M968,5630h65l-4,5H972Z" transform="translate(-968 -5630)" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="social__item">
                  <div onClick={()=>window.open(SocialLinks.link_discord,'_blank')}>
                    <i className="flaticon-discord"></i>
                    <span>discord</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="5" viewBox="0 0 65 5">
                      <path d="M968,5630h65l-4,5H972Z" transform="translate(-968 -5630)" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  )
}

export default Home;
