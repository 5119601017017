import REFERRAL_CODE_ACCESSED_AUTH           from "../Utils/AUTH/REFERRAL_CODE_ACCESSED_AUTH";

export const useReferralCode = (invitation) => {
  const referralCode = async () => {
    if(!invitation) return;
    try {
      await REFERRAL_CODE_ACCESSED_AUTH({ invitation });
    } catch (error) {
      // console.log(error);
    }
  };

  return { referralCode };
};
