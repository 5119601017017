import { useState, useContext, useEffect }            from "react";
import { useNavigate, useParams  }                    from "react-router-dom";
import Zoom                                           from "react-medium-image-zoom";
import { useSnackbar }                                from 'notistack';
import axios                                          from "axios";

// import PopUpAds                                       from "../../Components/PopUpAds/PopUpAds";
import PopUpVideo                                     from "../../Components/PopUpVideo/PopUpVideo";

import Checkbox                                       from "@mui/material/Checkbox";
import FormControlLabel                               from "@mui/material/FormControlLabel";

import ReportProblemIcon                              from "@mui/icons-material/ReportProblem";
import FavoriteBorder                                 from "@mui/icons-material/FavoriteBorder";
import Favorite                                       from "@mui/icons-material/Favorite";
import LibraryBooksIcon                               from '@mui/icons-material/LibraryBooks';
import AutoAwesomeIcon                                from '@mui/icons-material/AutoAwesome';

import LoadingComponent                               from "../../Components/Common/LoadingComponent";
import ButtonActive                                   from "../../Components/ButtonActive/ButtonActive";
import ComponentNotFound                              from "../../Components/404/404";

import Context                                        from "../../Provider/Context";

import background_button_normal                       from "../../assets/img/ui/button/normal.png"
import background_button_error                        from "../../assets/img/ui/button/error.png"
import background_button_success                      from "../../assets/img/ui/button/success.png"
import icon_vip                                       from "../../assets/img/icons/ui/vip.png";

/* IMPORT LANGUAGE */
import { _lang_product_ui, _lang_base }               from "../../Languages/languages";

function ProductUI() {
  const { enqueueSnackbar }                           = useSnackbar();
  const history                                       = useNavigate();
  const { UserInfoBase, Products, SocialLinks, 
    UserConfigBase, DialogBase }                      = useContext(Context);
  const UserInfo                                      = UserInfoBase.Value;
  const UserConfig                                    = UserConfigBase.Value;

  const { product }                                   = useParams();

  const user_infos                                    = UserInfo;

  const product_info                                  = [...Products.free, ...Products.vip].find(item => item.product_slug === product);

  const [favo_product, set_favo_product]              = useState(false);
  const [btn_active, set_btn_active]                  = useState(false);
  const [btn_pulse_active, set_btn_pulse_active]      = useState(true);

  const [btn_type_active,  set_btn_type_active]       = useState("Inject");
  const [btn_text_active,  set_btn_text_active]       = useState(_lang_product_ui.text_btn_inject);

  const [open_pop_up_ads, set_open_pop_up_ads]        = useState(false);
  const [redirect_product, set_redirect_product]      = useState("");

  useEffect(() => {
    if(!product_info || product_info.product_category_id !== 1) return;
    if(user_infos) {
      if(UserConfig.products_favorites !== undefined) set_favo_product(UserConfig.products_favorites.includes(product_info.product_id));
      set_btn_active((product_info.product_status_id === 1) ? false : true);
      // if(user_infos.product_vip.length <= 0) set_open_pop_up_ads(true);
      if(product_info.product_type_id === 1) {
        set_redirect_product("/store/projectfreepass");
      } else {
        set_redirect_product(`/store/${product_info.product_slug}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_info, user_infos]);

  if(Products.vip === undefined || Products.free === undefined) return (<ComponentNotFound />);
  if (!product_info || !product_info.product_information || product_info.product_category_id !== 1 || product_info.product_enable_ui !== 1) return (<ComponentNotFound />);

  const product_properties               = [
    {
      title: _lang_product_ui.product_properties.game,
      value: product_info.product_information.product_title,
    },
    {
      title: _lang_product_ui.product_properties.status,
      value: product_info.product_information.product_status,
    },
    {
      title: _lang_product_ui.product_properties.type,
      value: (product_info.product_type_id === 1) ? "free" : "vip",
    },
    {
      title: _lang_product_ui.product_properties.platform,
      value: product_info.product_platform,
    },
    {
      title: _lang_product_ui.product_properties.compatible,
      value: product_info.product_compatible,
    },
  ];

  const report_function = ({typeError}) => {
    switch(typeError) {
      case "compra":
        if(product_info.product_type_id === 1) {
          history(`/store/projectfreepass`);
        } else {
          history(`/store/${product_info.product_slug}`);
        }
        break;
      case "suporte":
        window.open(SocialLinks.link_discord, '_blank');
        break;
      default:
        break;
    }
  }

  const fetch_product = async () => {
    try {
      const request_data = JSON.stringify({ user_token: user_infos.user.user_token, request_id: `${product_info.product_id}`});
      const response     = await axios.post("http://localhost:5784/download", request_data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
      });

      const response_text = response.data;

      set_btn_type_active(_lang_product_ui.products_reports[response_text].type);
      set_btn_text_active(_lang_product_ui.products_reports[response_text].msg);
      enqueueSnackbar(_lang_product_ui.products_reports[response_text].msg, { variant: "success" });
      report_function(_lang_product_ui.products_reports[response_text]);
  
    } catch (error) {
      set_btn_type_active(_lang_product_ui.products_reports["10"].type);
      set_btn_text_active(_lang_product_ui.products_reports["10"].msg);
      enqueueSnackbar(_lang_product_ui.products_reports["10"].msg, { variant: "error" });
      // window.open(SocialLinks.link_discord, "_blank");
    }
  }

  const fech_loader = async () => {
    try {
      const response = await axios.post("http://localhost:5784/status", "open", {
        headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" }
      });

      if (response.status === 200) await fetch_product();
  
    } catch (error) {
      await fech_loader();
    }
  };

  const active_product = () => {
    if(!user_infos) {
      enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
      history("/login");
      return;
    }

    if(user_infos.product_vip.length <= 0) set_open_pop_up_ads(true);
    const fetch_vip = user_infos.product_vip.find((item) => Number(item.product_id) === Number(product_info.product_id));
    if(!fetch_vip && product_info.product_type_id !== 1) {
      set_open_pop_up_ads(true);
    }

    set_btn_pulse_active(false);
    set_btn_type_active(_lang_product_ui.products_reports['11'].type);
    set_btn_text_active(_lang_product_ui.products_reports['11'].msg);
    fech_loader();
  }

  const component_loading_video = <div className="slider-panel-slide-image"> <LoadingComponent /> </div>
  const preview_video =
    <iframe className="slider-panel-slide-image" src={product_info.product_video} 
      title="YouTube video player" allow="accelerometer; autoplay; 
      clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen>
    </iframe>

  const components_product_properties = product_properties.map((item, index) => 
    <div key={`product_properties_${index}`} className="information-line">
      <p className="information-line-title">{item.title}</p>
      <p className="information-line-text"><span className={`bold ${item.value}_information`}>{
        (item.value === "vip") && <img src={icon_vip} alt={_lang_base.web_site_name} />}
        {item.value}</span>
      </p>
    </div>
  )

  const product_pre_imgs              = product_info.product_imgs.map((item, index) => 
    <div key={`pre_img_${index}`} className="roster-picture roster-picture-v2">
      <figure className="roster-picture-image liquid">
        <Zoom><img src={`https://projectdow.com/data${item}`} alt={_lang_base.web_site_name} /></Zoom>
      </figure>
    </div>
  )

  const favorite_product              = () => {
    if(UserConfig.products_favorites !== undefined)
    if(!user_infos) {
      enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
      history("/login");
      return;
    }
    const get_product_favorite = UserConfig.products_favorites.find((item) => item === product_info.product_id);
    if(get_product_favorite) {
      const remove_item = UserConfig.products_favorites.filter((item) => item !== product_info.product_id);
      UserConfigBase.Update({ products_favorites: remove_item});
      set_favo_product(false);
      return;
    }
    UserConfigBase.Update({ products_favorites: [...UserConfig.products_favorites, product_info.product_id]});
    set_favo_product(true);
  }

  const btn_status_background         = () => {
    let background_img = background_button_normal;
    switch(btn_type_active) {
      case "error": 
        background_img = background_button_error;
        break;
      case "success": 
        background_img = background_button_success;
        break;
      default:
        background_img = background_button_normal;
        break;
    }
    return ({"--delay": ".5s", background: `url('${background_img}') center center / 100% 100% no-repeat` })
  }

  return(
    <>
      <PopUpVideo 
        open_pop_up_ads={open_pop_up_ads} 
        set_open_pop_up_ads={set_open_pop_up_ads}
        youtube_link={SocialLinks.link_ui_free_user_video}
        redirect_product={redirect_product}
      />
      <div className="img_game_product" style={{
        background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #161b28 100%), url('https://projectdow.com/data${product_info.product_base_ui_img_background}') center center / cover no-repeat`}}
      >  
      </div>
      <div className="profile container">
        <div className="content-grid">
          <div className="section-header section-header-v2">
            <div className="section-header-info">
              <p className="section-pretitle">{_lang_base.web_site_name}</p>
              <h2 className="section-title">{product_info.product_information.product_title}</h2>
            </div>
          </div>
          <div className="grid grid-9-3">
            <div className="marketplace-content grid-column">
              <div className="slider-panel">
                <div id="product-box-slider-items" className="slider-panel-slides">
                  <div className="slider-panel-slide">{component_loading_video}{preview_video}</div>
                </div>
                <div className="slider-panel-roster">
                  <div id="product-box-slider-controls" className="slider-controls">
                    <div className="slider-control left">
                      <svg className="slider-control-icon icon-small-arrow">
                        <use id="#svg-small-arrow"></use>
                      </svg>
                    </div>
                    <div className="slider-control right">
                      <svg className="slider-control-icon icon-small-arrow">
                        <use id="#svg-small-arrow"></use>
                      </svg>
                    </div>
                  </div>
                  <div 
                    onClick={()=> window.open(product_info.product_video.replace('embed/', 'watch?v='))}
                    className="button secondary"
                  >{_lang_product_ui.btn_video}</div>
                  <div id="product-box-slider-roster" className="roster-pictures">
                    {product_pre_imgs}
                  </div>
                </div>
              </div>
            </div>
            <div className="marketplace-sidebar">
              <div className="sidebar-box sidebar-box-two">
                <div className="sidebar-box-items">
                  <div className="button-wrapper">
                    <button 
                      onClick={() => active_product()}
                      disabled={btn_active} id="button-inject" 
                      className={ `${(btn_pulse_active) ? "ready anim loading pulse-button" : "ready"} `} 
                      style={btn_status_background()}
                    >
                      <ButtonActive key="button-inject" tipo={btn_type_active} texto={btn_text_active} />
                    </button>
                  </div>
                  <div className="user-stats"></div>
                </div>
                <div className="sidebar-box-items">
                  <div className="information-line-list">{components_product_properties}</div>
                </div>

                <div className="sidebar-box-items">
                  <div className="social-links small align-center">
                    <FormControlLabel
                        control={
                          <Checkbox 
                            onClick={favorite_product}
                            checked={favo_product}
                            icon={<FavoriteBorder sx={{ color: "white" }} />} 
                            checkedIcon={<Favorite sx={{ color: "red" }} />} 
                          />
                        }
                        label={_lang_product_ui.label_favorite}
                      />
                    <FormControlLabel
                        control={
                          <Checkbox 
                            onClick={() => {
                              if(!user_infos) {
                                enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
                                history("/login");
                              }                         
                              DialogBase.Update({ status: true, dialog_id: 3, maxWidth: "sm", product_id: product_info.product_id }); 
                            }}
                            checked={false}
                            icon={<ReportProblemIcon sx={{ color: "white" }} />} 
                          />
                        }
                        label={_lang_product_ui.label_report_problem}
                      />
                    <FormControlLabel
                        control={
                          <Checkbox 
                            onClick={() => {
                              history(`/changelogs/${product_info.product_slug}`);
                              
                            }}
                            checked={false}
                            icon={<LibraryBooksIcon sx={{ color: "white" }} />} 
                          />
                        }
                        label={_lang_product_ui.label_change_logs}
                      />
                  </div>
                </div>
              </div>
              <div className="btn_request_product">
                <FormControlLabel
                  control={
                    <Checkbox 
                      onClick={() => {
                        if(!user_infos) {
                          enqueueSnackbar(_lang_base.requests.not_logged_in, {variant: 'error'});
                          history("/login");
                        }                         
                        DialogBase.Update({ status: true, dialog_id: 4, maxWidth: "sm", product_id: product_info.product_id }); 
                      }}
                      checked={false}
                      icon={<AutoAwesomeIcon sx={{ color: "yellow" }} />} 
                    />
                  }
                  sx={{ color: "yellow" }}
                  label={_lang_product_ui.label_request_mod}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductUI;
