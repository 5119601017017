import { Routes, Route }              from "react-router-dom";

import Home                           from "../Home/Home";
import Login                          from "../Login/Login";
import Profile                        from "../Profile/Profile";
import UI                             from "../UI/UI";
import PageProduct                    from "../PageProduct/PageProduct";
import Store                          from "../Store/Store";
import OnePayment                     from "../OnePayment/OnePayment";
import ProductUI                      from "../ProductUI/ProductUI";
import Cart                           from "../Cart/Cart";
import Checkout                       from "../Checkout/Checkout";
import RefundPolicy                   from "../RefundPolicy/RefundPolicy";
import TermsOfUse                     from "../TermsOfUse/TermsOfUse";
import TermsPrivacy                   from "../TermsPrivacy/TermsPrivacy";
import Faq                            from "../Faq/Faq";
import CheckoutPay                    from "../CheckoutPay/CheckoutPay";
import LostPassword                   from "../LostPassword/LostPassword";
import Discord                        from "../Discord/Discord";
import ChangeLogs                     from "../ChangeLogs/ChangeLogs";
// import ConfigProduct                  from "../ConfigProduct/ConfigProduct";

import Hub                            from "../Hub/Hub";

const Pages = () => {
  return(
    <Routes>
      <Route exact path='/:slug'               Component={ Home }            />
      <Route exact path='/'                    Component={ Home }            />
      <Route exact path='/login'               Component={ Login }           />
      <Route exact path='/profile'             Component={ Profile }         />
      <Route exact path='/ui'                  Component={ UI }              />
      <Route exact path='/ui/:product'         Component={ ProductUI }       />
      <Route exact path='/store'               Component={ Store }           />
      <Route exact path='/store/:product'      Component={ PageProduct }     />
      <Route exact path='/cart'                Component={ Cart }            />
      <Route exact path='/checkout'            Component={ Checkout }        />
      <Route exact path='/checkout/:order'     Component={ CheckoutPay }     />
      <Route exact path='/onepayment'          Component={ OnePayment }      />
      <Route exact path='/refund-policy'       Component={ RefundPolicy }    />
      <Route exact path='/terms-of-use'        Component={ TermsOfUse }      />
      <Route exact path='/privacy-terms'       Component={ TermsPrivacy }    />
      <Route exact path='/faq'                 Component={ Faq }             />
      <Route exact path='/lostpassword'        Component={ LostPassword }    />
      <Route exact path='/lostpassword/:slug'  Component={ LostPassword }    />
      <Route exact path='/link-discord'        Component={ Discord }         />
      <Route exact path='/changelogs/:product' Component={ ChangeLogs }      />
      <Route exact path='/hub'                 Component={ Hub }             />
      <Route exact path='/hub/:slug'           Component={ Hub }             />
      {/* <Route exact path='/config/:slug'        Component={ ConfigProduct }   /> */}
      {/* <Route exact path='/config'              Component={ ConfigProduct }   /> */}
    </Routes>
  )
}

export default Pages;
