import { _remove_storage, storage_vars, _get_storage, _save_storage } from "../Storage/Storage";

const max_size_user_login         = 15;
const min_size_user_login         = 4;

const max_size_password           = 15;
const min_size_password           = 8;

export function verify_user_login(user_login) {
  if (!/^[a-zA-Z]+$/.test(user_login)) {
    throw new Error('600');
  } else if (user_login.length >= max_size_user_login) {
    throw new Error('602');
  } else if (user_login.length <= min_size_user_login) {
    throw new Error("601");
  }
}

export function verify_user_email(user_email) {
  if (!/\S+@\S+\.\S+/.test(user_email)) throw new Error('603');
}

export function verify_user_password(user_password, confi_password) {
  if(user_password !== confi_password) {
    throw new Error('612');
  } else if (user_password.length >= max_size_password) {
    throw new Error('611');
  } else if (user_password.length <= min_size_password) {
    throw new Error('610');
  }
}

export function EXIT_ACCOUNT() {
  _remove_storage(storage_vars.user_auth);
  _remove_storage(storage_vars.chat_auth);
  _remove_storage(storage_vars.cart);
  _remove_storage(storage_vars.popups);
  _remove_storage(storage_vars.checkout);
  _remove_storage(storage_vars.coupons);
  _remove_storage(storage_vars.user_config);
  window.location.href = "/login";
  return;
}

export function check_date_popup(data) {
  const now = new Date();
  const date_purchase = new Date(data);
  if (now.getDate() !== date_purchase.getDate()) {
      return true;
  } else {
      return false;
  }
}

export function verify_token_expired(status_code) {
  if(status_code === "402" ||  status_code === "403") {
    EXIT_ACCOUNT();
    return;
  }
}

export function verify_code_length(code) {
  if(!code.includes("invitation=")) throw new Error('121');
  const code_split = code.split("invitation=")[1];
  if(code_split.length !== 30) throw new Error('118');

  const referral_code = _get_storage(storage_vars.referral_code);
  if(referral_code !== null && referral_code.invitation === code_split) throw new Error('122');

  _save_storage(storage_vars.referral_code, { invitation: code_split });
  return code_split;
}

export function get_referral_code() {
  const referral_code = _get_storage(storage_vars.referral_code);
  if(referral_code === null || referral_code.invitation === undefined) return "";
  if(referral_code.invitation.length !== 30) return "";
  return referral_code.invitation;
}
