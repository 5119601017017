import { AUTH }                                         from "../../Api/Conexao";
import { _lang_base }                                   from "../../Languages/languages";
import { verify_code_length }                           from "../UserFunction";

const REFERRAL_CODE_ACCESSED_AUTH = async (current_user) => {
  let invitation = "";
  try {
    invitation = verify_code_length(current_user.invitation);
  } catch (error) {
    const status_code = _lang_base.requests[error.message];
    return { status_code_message: status_code, success: false };
  }

  try {
    const data = new URLSearchParams();
    data.append('invitation',  invitation);
    const request = await AUTH.post('/routes/referral_code_accessed.php', data).then((response) => response.data);
    request.status_code_message = _lang_base.requests[request.status_code]
    return request;
  } catch (error) {
    const status_code = _lang_base.requests[error.response.data.status_code];
    return { status_code_message: status_code, success: error.response.data.success };
  }
}

export default REFERRAL_CODE_ACCESSED_AUTH;
